/* Layout for the cards */
.pet-listing {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    padding: 16px;
}

/* Card customization */
.pet-card {
    width: 100%;
    margin: 0 auto;
}

/* Responsive grid */
@media (min-width: 1024px) {
    .pet-listing {
        grid-template-columns: repeat(3, 1fr);
        /* 3 cards on large screens */
    }
}

@media (min-width: 1280px) {
    .pet-listing {
        grid-template-columns: repeat(4, 1fr);
        /* 4 cards on extra-large screens */
    }
}

@media (max-width: 768px) {
    .pet-listing {
        grid-template-columns: repeat(1, 1fr);
        /* 1 card per row on mobile */
    }
}

/* Style the clickable title */
.card-title-link {
    text-decoration: underline;
    color: inherit;
}

.card-title-link:hover {
    color: #0056b3;
    /* Darker color on hover */
}