/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bg {
  padding-bottom: 0px;

  background: url('/src/bg/1.png') right bottom no-repeat, url('/src/bg/7.png') left bottom no-repeat;

}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #555;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

/* Add this to your CSS file or style tag */
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* Ensure it's above other content */
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white background */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CSS for sliding animation */
.slide-in {
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
}

.slide-out {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

/* Container holding the loader and text */
.loader-content {
  display: flex;
  align-items: center;
}

.background-image {
  background: url('../public/bg/find_pets.png') center/cover no-repeat;
  opacity: 0.8;
  /* Adjust the opacity value as needed */
  filter: alpha(opacity=80);
  min-height: 500px;
  /* For older IE */
}

.jumbotron {
  padding: 0;
}

.lead {
  font-size: 1.1rem;
  font-weight: 300;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}