/* PetDetail Layout */
.PetDetail {
    max-width: 100%;
    padding: 0 20px;
}

.pet-card {
    max-width: 800px;
    /* Max width for desktop */
    margin: 20px auto;
    width: 100%;
}

.pet-image {
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 600px;
    /* Max height for desktop */
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .pet-image {
        max-height: none;
        /* Remove fixed height on mobile */
        height: 60vw;
        /* Height based on viewport width */
    }
}