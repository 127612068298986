/* Center-align the images using Flexbox */
.image-container {
    display: flex;
    flex-direction: column;
    /* Stack images vertically */
    justify-content: center;
    /* Center vertically */
    align-items: center;
    /* Center horizontally */

    /* Optional: Adjust container height as needed */
}

/* Hide mobile images by default */
.mobile-image {
    display: none;
}

/* Show desktop image by default */
.desktop-image {
    display: block;
}

/* Media query for screens smaller than 768px (mobile devices) */
@media (max-width: 768px) {
    .mobile-image {
        display: block;
    }

    .desktop-image {
        display: none;
    }
}